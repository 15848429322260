
    export default {
        name: "IconEdit",
        props: {
            fill: {
                default: "#524F5A",
                type: String
            }
        }
    }
