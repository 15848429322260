import { render, staticRenderFns } from "./ControlSite.vue?vue&type=template&id=c913300c"
import script from "./ControlSite.vue?vue&type=script&lang=js"
export * from "./ControlSite.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconAlignLeftText: require('/usr/src/eventum-portal/components/icon/align-left-text.vue').default,IconAlignCenterText: require('/usr/src/eventum-portal/components/icon/align-center-text.vue').default,IconAlignRightText: require('/usr/src/eventum-portal/components/icon/align-right-text.vue').default,IconEdit: require('/usr/src/eventum-portal/components/icon/IconEdit.vue').default})
