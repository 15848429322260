
    export default {
        name: "ControlSite",
        props: {
            bgEdit: {
                default: false,
                type: Boolean
            },
            showAlignmentControls: {
                default: false,
                type: Boolean
            },
            target: {
                default: "",
                type: String
            },
            type: {
                default: "",
                type: String
            },
            top: {
                default: "top-0",
                type: String
            },
            updates: {
                default: () => ([]),
                type: Array
            }
        },
        methods: {
            setTextAlignment(val) {
                const obj = {
                    target: this.target, type: this.type, nest: 'alignment', value: val
                };

                this.$emit("update-event", obj);
            },
             updateParent(val) {
                const { value, target, type } = val;

                const obj = {
                    target: this.target, type: target, nest: type, value: value
                };

                this.$emit("update-event", obj);
            }
        }
    }
