/**
 * @author Jason Curren
 * @description Reusable props for heros
 * ================================================================================================
 */

export const heroProps = {
    props: {
        edit: {
            default: false,
            type: Boolean
        },
        hero: {
            default: () => ({
                heading: "",
                paragraph: ""
            }),
            type: Object
        },
        products: {
            default: () => ([]),
            type: Array
        },
        show: {
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            heroText: {
                heading: "",
                paragraph: ""
            }
        }
    },
    mounted() {
        if (this.hero.heading.text) this.heroText.heading = this.hero.heading.text;
        if (this.hero.paragraph.text) this.heroText.paragraph = this.hero.paragraph.text;
    },
    computed: {
        bgColor() {
            let output = "bg-white";

            if (this.hero.background && this.hero.background.color) {
                output = `bg-${this.hero.background.color}`;
            }

            return output;
        },
        bgImage() {
            let output = "";

            if (this.hero.background && this.hero.background.image) {
                const route = this.hero.background.image.includes("https") ? "" : "/";

                output = `background-image: url(${route}${this.hero.background.image});`;
            }

            return output;
        },
        heroColor() {
            let output = "bg-black";

            if (this.hero.heading && this.hero.heading.color) {
                output = `text-${this.hero.heading.color}`;
            }

            return output;
        },
        heroFont() {
            let output = "";

            if (this.hero.heading && this.hero.heading.font) {
                output = `font-family: ${this.hero.heading.font}`;
            }

            return output;
        },
        heroHeaderAlignment() {
            let output = `text-center`;

            if (this.hero.heading && this.hero.heading.alignment) {
                output = `${this.hero.heading.alignment}`;
            }

            return output;
        },
        heroParagraphAlignment() {
            let output = `text-center`;


            if (this.hero.paragraph && this.hero.paragraph.alignment) {
                output = `${this.hero.paragraph.alignment}`;
            }

            return output;
        },
        paraColor() {
            let output = "bg-black";

            if (this.hero.paragraph && this.hero.paragraph.color) {
                output = `text-${this.hero.paragraph.color}`;
            }

            return output;
        },
        paraFont() {
            let output = "";

            if (this.hero.paragraph && this.hero.paragraph.font) {
                output = `font-family: ${this.hero.paragraph.font}`;
            }

            return output;
        },
    },
    methods: {
        updateHeading(e) {
            if (this.edit) {
                this.$emit("update-heading", e.target.innerHTML);
            }
        },
        updateParagraph(e) {
            if (this.edit) {
                this.$emit("update-paragraph", e.target.innerHTML);
            }
        },
        viewProduct(val) {
            this.$emit("view-product", val);
        }
    }
}